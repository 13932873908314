const name = 'Colorado_Supermoto'

const races = [

     {
         name: name,
         track: 'IMI Motorsports Park - Dacono, Co',
         date: [5,25],
         sortDate: .0525
     },
     {
         name: name,
         track: 'Colorado National Speedway - Dacono, Co',
         date: [5,4],
         sortDate: .0504
     },
     {
         name: name,
         track: 'TBD',
         date: [6,8],
         sortDate: .0608
     },
     {
         name: name,
         track: '(NASS Q) IMI Motorsports Park - Dacono, Co',
         date: [7,13],
         sortDate: .0713
     },
     {
         name: name,
         track: 'MI Motorsports Park - Dacono, Co',
         date: [8,3],
         sortDate: .0803
     },
     {
        name: name,
        track: 'Pueblo Motorsports Park - Pueblo, Co' ,
        date: [9,7],
        sortDate: .0907
    },
]
const exportRaces = {
    races, name
}
export default exportRaces