const name = 'Cascadia_Super_Moto'

const races = [
    {
        name: name,
        track: 'TCKC - Richland, Wa' ,
        date: [4,28],
        sortDate: .0428
    },
    {
        name: name,
        track: 'Spokane Kart Racing Association - Spokane, Wa',
        date: [6,16],
        sortDate: .0615
    },
    {
        name: name,
        track: 'Spokane Kart Racing Association - Spokane, Wa',
        date: [8,11],
        sortDate: .0810
    },
    {
        name: name,
        track: 'TCKC (Double Header) - Richland, Wa',
        date: [8,31,32],
        sortDate: .0831
    },
    {
        name: name,
        track: 'Spokane Kart Racing Association (Awards) - Spokane, Wa',
        date: [9,21,22],
        sortDate: .0921
    }
]
const exportRaces = {
    races, name
}
export default exportRaces