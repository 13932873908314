const name = 'AMRA'

const races = [
     {
         name: name,
         track: '(Endurance) Strathmore Motorsports Park, Strathmore, AB, Canada' ,
         date: [5,5],
         sortDate: .0505
     },
     {
         name: name,
         track: 'Strathmore Motorsports Park, Strathmore, AB, Canada',
         date: [6,28,30],
         sortDate: .0628
     },
     {
         name: name,
         track: 'Strathmore Motorsports Park, Strathmore, AB, Canada',
         date: [7,26,28],
         sortDate: .0726
     },
     {
         name: name,
         track: 'Strathmore Motorsports Park, Strathmore, AB, Canada',
         date: [8,30,1],
         sortDate: .0830
     },
     {
        name: name,
        track: '(Endurance) Strathmore Motorsports Park, Strathmore, AB, Canada',
        date: [9,21],
        sortDate: .0921
    }
]
const exportRaces = {
    races, name
}
export default exportRaces