const name = 'NorthWest_Mini_Moto'

const races = [
    {
        name: name,
        track: 'TCKC (Sprints/6hr) - Richland, Wa' ,
        date: [5,11,12],
        sortDate: .0511
    },
    {
        name: name,
        track: 'Mac Track (Sprints/6hr) - McMinnville, Or',
        date: [6,1,2],
        sortDate: .0601
    },
    {
        name: name,
        track: 'TCKC (Sprints/6hr) - Richland, Wa',
        date: [7,19,20],
        sortDate: .0719
    },
    {
        name: name,
        track: 'Mac Track (Sprints/special)- McMinnville, Or',
        date: [8,31,32],
        sortDate: .0831
    },
    {
        name: name,
        track: 'TCKC (12hr) - Richland, Wa',
        date: [10,12,13],
        sortDate: .1012
    }
]
const exportRaces = {
    races, name
}
export default exportRaces