import React from 'react';
import './css/contact.css'
const Contact = () => {

return (
	<div>
    <iframe src='https://docs.google.com/forms/d/e/1FAIpQLSc-h1HVDSm1FzEyQWziX7xJbpY1YXljkCrcqSDCjFyg747djA/viewform' title='Contact Form'></iframe>
	</div>
);
};

export default Contact;
