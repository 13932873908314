const name = '2WTD_mini_racing'

const races = [
     {
         name: name,
         track: 'Apex CCW/CW - Perris, Ca' ,
         date: [3,30,31],
         sortDate: .0330
     },
     {
         name: name,
         track: 'Horse Thief (Willow Springs) - Rosamond, Ca',
         date: [4,13],
         sortDate: .0413
     },
     {
         name: name,
         track: 'Horse Thief (Willow Springs) - Rosamond, Ca',
         date: [5,11],
         sortDate: .0511
     },
     {
         name: name,
         track: 'Apex CW - Perris, Ca',
         date: [9,28],
         sortDate: .0928
     },
     {
         name: name,
         track: 'Apex CCW - Perris, Ca',
         date: [12,7],
         sortDate: .1207
     }
]
const exportRaces = {
    races, name
}
export default exportRaces