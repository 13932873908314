const name = 'WMRC'

const races = [
    {
        name: name,
        track: 'Mission Raceway Park - British Columbia, CAN' ,
        date: [5,10,12],
        sortDate: .0510
    },
    {
        name: name,
        track: 'Mission Raceway Park - British Columbia, CAN',
        date: [7,5,7],
        sortDate: .0705
    },
    {
        name: name,
        track: 'Mission Raceway Park - British Columbia, CAN',
        date: [8,30,32],
        sortDate: .0830
    },
    {
        name: name,
        track: 'Mission Raceway Park - British Columbia, CAN',
        date: [10,11,13],
        sortDate: .1011
    }
]
const exportRaces = {
    races, name
}
export default exportRaces