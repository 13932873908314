const name = 'PCMRC'

const races = [
     {
         name: name,
         track: 'Greg Moore Raceway - British Columbia, CAN' ,
         date: [5,25,26],
         sortDate: .0525
     },
     {
         name: name,
         track: 'Greg Moore Raceway - British Columbia, CAN',
         date: [6,22,23],
         sortDate: .0622
     },
     {
         name: name,
         track: 'Greg Moore Raceway - British Columbia, CAN',
         date: [7,27,28],
         sortDate: .0727
     },
     {
         name: name,
         track: 'Cariboo Raceway Park - Quesnel, CAN',
         date: [8,3,4],
         sortDate: .0803
     },
     {
         name: name,
         track: 'Greg Moore Raceway - British Columbia, CAN',
         date: [8,24,25],
         sortDate: .0824
     }
]
const exportRaces = {
    races, name
}
export default exportRaces