const name = 'Shasta_SuperMoto_Club'

const races = [
    {
        name: name,
        track: 'Shasta Kart Klub - Redding, Ca' ,
        date: [7,27,28],
        schedule: '/Shasta',
        scheduleName: 'Detailed Schedule',
        sortDate: .0727
    },
    {
        name: name,
        track: 'Shasta Kart Klub - Redding, Ca',
        date: [4,6,7],
        sortDate: .0406
    },
    {
        name: name,
        track: 'Shasta Kart Klub - Redding, Ca',
        date: [6,8,9],
        sortDate: .0608
    },
    {
        name: name,
        track: 'Shasta Kart Klub - Redding, Ca',
        date: [7,6,7],
        sortDate: .0706
    },
    {
        name: name,
        track: 'Shasta Kart Klub - Redding, Ca',
        date: [8,17,18],
        sortDate: .0817
    },
    {
        name: name,
        track: 'Shasta Kart Klub - Redding, Ca',
        date: [10,5,6],
        sortDate: .1005
    }
]
const exportRaces = {
    races, name
}
export default exportRaces