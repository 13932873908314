const name = 'UTAHSBA_SuperMoto'

const races = [
    {
        name: name,
        track: 'Utah Motorsports Campus - Erda, Ut',
        date: [4,28],
        sortDate: .0428
    },
    {
        name: name,
        track: 'Utah Motorsports Campus - Erda, Ut',
        date: [5,19],
        sortDate: .0519
    },
    {
        name: name,
        track: 'Utah Motorsports Campus - Erda, Ut',
        date: [6,23],
        sortDate: .0623
    },
    {
        name: name,
        track: 'Utah Motorsports Campus - Erda, Ut',
        date: [8,18],
        sortDate: .0818
    },
    {
        name: name,
        track: 'Utah Motorsports Campus - Erda, Ut',
        date: [9,8],
        sortDate: .0908
    },
    {
        name: name,
        track: 'Utah Motorsports Campus - Erda, Ut',
        date: [9,29],
        sortDate: .0929
    }
]
const exportRaces = {
    races, name
}
export default exportRaces