const name = 'AMA_SUPERMOTO'

const races = [
    // {
    //     name: name,
    //     track: 'Horse Thief Mile - Rosamond, Ca' ,
    //     date: [4,8],
    //     sortDate: .0408
    // },
    // {
    //     name: name,
    //     track: 'Musselman Honda Circuit - Tucson, Az',
    //     date: [5,6,7],
    //     sortDate: .0506
    // },
    // {
    //     name: name,
    //     track: 'Colorado National Speedwaay - Dacona, Co',
    //     date: [6,3],
    //     sortDate: .0603
    // },
    // {
    //     name: name,
    //     track: 'IMI Motorsports Complex - Dacona, Co',
    //     date: [6,4],
    //     sortDate: .0604
    // },
    // {
    //     name: name,
    //     track: 'Grand Prix de Trois-Rivieres - Quebec, Canada',
    //     date: [8,12,13],
    //     sortDate: .0812
    // },
    // {
    //     name: name,
    //     track: 'Road Atlanta Motor Plex',
    //     date: [9,24],
    //     sortDate: .0924
    // }
]
const exportRaces = {
    races, name
}
export default exportRaces